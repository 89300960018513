<template>
  <div>
    <CCard>
      <CCardHeader>Edit Game</CCardHeader>
      <CCardBody>
        <CRow>
          <CCol md="12" lg="6">
            <CForm @submit="submitForm">
              <div class="form-group vmselect" v-bind:class="{ valid: validateInput(sitesInput.val, 'sites'), invalid: !validateInput(sitesInput.val, 'sites') }">
                <multiselect v-model="selectedSites" tag-placeholder="Add this as new tag" placeholder="Search or add a tag" label="name" track-by="code" :options="siteOptions" :multiple="true" :taggable="true" :show-labels="false" :custom-label="customLabel"></multiselect>
                <div class='invalid-feedback'>{{ invalidFeedback('sites') }}</div>
              </div>
              <CInput required placeholder='Game Name' v-model='nameInput.val' v-bind:class="{ valid: validateInput(nameInput.val, 'name') }" :invalidFeedback="invalidFeedback('name')" :isValid="validateInput(nameInput.val, 'name')">
                <template #prepend-content><CIcon name='cil-user'/></template>
              </CInput>
              <CSelect required placeholder='Game Type' :value.sync='typeInput.val' v-bind:class="{ valid: validateInput(typeInput.val, 'type') }" :invalidFeedback="invalidFeedback('type')" :isValid="validateInput(typeInput.val, 'type')" :options="[ 
                  { value: '', label: 'Unassigned' }, 
                  { value: 'SLT', label: 'Slots' }, 
                  { value: 'FTB', label: 'Fish Tables' }, 
                  { value: 'ARC', label: 'Arcade' }, 
                  { value: 'LOT', label: 'Lottery' }, 
                  { value: 'ECA', label: 'ECasino' },
                  { value: 'OTH', label: 'Others' }  
              ]">
                <template #prepend-content><CIcon name='cil-gamepad'/></template>
              </CSelect>
              <div class="form-group">
                <CInputRadioGroup inline :options="[{ value: '0', label: 'Newly Synced (Inactive)'}, { value: '1', label: 'Active'}, { value: '2', label: 'Inactive'}]" :checked.sync='statusInput.val'/>
              </div>
              <div class="form-group form-actions">
                <CButton block type='submit' color='success' :disabled='formLoading'>
                  <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='formLoading'></span>
                  Update
                </CButton>
              </div>
              <div class="form-group form-actions">
                <CLink @click='deleteItemModalShow()' :disabled='deleteLoading'>
                  <CIcon name='cil-trash' /> Delete this cashtag <span class='spinner-border spinner-border-sm' role='status' aria-hidden='true' v-if='deleteLoading'></span>
                </CLink>
              </div>
            </CForm>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CModal title='Delete Game' color='danger' :centered='true' :show.sync='deleteModal' @update:show='deleteItemConfirm'>
      Are you sure you wish to delete this game '{{nameInput.val}}'?
    </CModal>
  </div>
</template>

<script>
import apiGame from '@/api/game'
import apiSite from '@/api/site'
import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
  name: 'EditGame',
  components: {
    Multiselect
  },
  watch: {
    'selectedSites': function() {
      this.sitesInput.val = []
      this.selectedSites.forEach((site) => {
        this.sitesInput.val.push(site.code)
      })
    }
  },
  data () {
    return {
      formLoading: false,
      deleteLoading: false,
      nameInput: { val: null, serverErrMsg: null , serverErrVal: null },
      typeInput: { val: null, serverErrMsg: null , serverErrVal: null },
      statusInput: { val: null, serverErrMsg: null , serverErrVal: null },
      sitesInput: { val: [], serverErrMsg: null , serverErrVal: [] },
      selectedSites: [],
      siteOptions: [],
      deleteModal: false
    }
  },
  async created() {
    var res1 = await apiSite.list(1, 200, null, null, {status:0})
    if(res1){
      this.siteOptions = res1.data.data
    }else{
      this.$toast.error('Error loading list.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
    }
    var res2 = await apiGame.get(this.$route.params.id)
    if(res2){
      this.nameInput.val = res2.data.game.name
      if(res2.data.game.sites){
        this.sitesInput.val = res2.data.game.sites
        this.siteOptions.forEach((opt) => {
          this.sitesInput.val.forEach((opt1) => {
            if(opt.code == opt1){
              this.selectedSites.push(opt)
            }
          })
        })
      }
      this.statusInput.val = ''+res2.data.game.status
      this.typeInput.val = res2.data.game.type
    }else{
      this.$toast.error('Error retrieving item.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
    }
  },
  methods: {
    customLabel ({ name, domain, code }) {
      return `${code} – ${name} (${domain})`
    },
    validateInput (val, field) {
      if(val === null){
      }else{
        if(field == 'name'){ // max length 100 chars
          return (val && val.length <= 100 && val !== this.nameInput.serverErrVal) ? true : false
        }
        if(field == 'type'){ // 3 chars
          return (val !== this.typeInput.serverErrVal) ? true : false
        }
        if(field == 'sites'){ // valid sites
          var is_same = (val.length == this.sitesInput.serverErrVal.length) && val.every((element, index) => {
            return element === this.sitesInput.serverErrVal[index]; 
          });
          return (val && val.length > 0 && !is_same) ? true : false
        }
      }
    },
    invalidFeedback (field) {
      if(field == 'name'){
        return (this.nameInput.val !== this.nameInput.serverErrVal) ? 'Max 100 characters' : this.nameInput.serverErrMsg
      }
      if(field == 'type'){
        return (this.typeInput.val !== this.typeInput.serverErrVal) ? 'Max 3 characters' : this.typeInput.serverErrMsg
      }
      if(field == 'sites'){
        var is_same = (this.sitesInput.val.length == this.sitesInput.serverErrVal.length) && this.sitesInput.val.every((element, index) => {
          return element === this.sitesInput.serverErrVal[index]; 
        });
        return !this.sitesInput.serverErrMsg ? 'Invalid site(s) selected' : this.sitesInput.serverErrMsg
      }
    },
    submitForm: function (e) {
      
      e.preventDefault();
      
      if(this.validateInput(this.nameInput.val, 'name') && this.validateInput(this.sitesInput.val, 'sites') && this.validateInput(this.typeInput.val, 'type')){

        this.formLoading = true
        
        apiGame.update(this.$route.params.id, this.nameInput.val, this.sitesInput.val, this.statusInput.val, this.typeInput.val).then(response => {
          this.formLoading = false
          this.$toast.success('Successfully updated game.', { position: 'top-right', timeout: 3000, hideProgressBar: true })
          this.$router.go(-1) 
        }).catch((err) => {
          this.formLoading = false
          if(err.response.data.status == 'validation.error' && err.response.data.errors){
            err.response.data.errors.forEach((error) => {
              if(error.field == 'name'){
                this.nameInput.serverErrVal = this.nameInput.val
                this.nameInput.serverErrMsg = error.message
              }
              if(error.field == 'type'){
                this.typeInput.serverErrVal = this.typeInput.val
                this.typeInput.serverErrMsg = error.message
              }
              if(error.field == 'sites'){
                this.sitesInput.serverErrVal = this.sitesInput.val
                this.sitesInput.serverErrMsg = error.message
              }
              if(error.field == 'status'){
                this.statusInput.serverErrVal = this.statusInput.val
                this.statusInput.serverErrMsg = error.message
              }
            });
          }
          this.$toast.error('Failed to update game.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        })
      }
    },
    deleteItemModalShow() {
      this.deleteModal = true
    },
    deleteItemConfirm(show, event, accepted) {
      if(accepted){
        this.deleteLoading = true
        apiCashtag.delete(this.$route.params.id).then(response => {
          this.deleteLoading = false
          this.$toast.success('Successfully deleted cashtag.', { position: 'top-right', timeout: 3000, hideProgressBar: true })
          this.$router.go(-1) 
        }).catch((err) => {
          this.deleteLoading = false
          this.$toast.error('Failed to delete cashtag.', { position: 'top-right', timeout: 5000, hideProgressBar: true })
        })
      }
    }
  }
}
</script>
