var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_vm._v("Edit Game")]),_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"12","lg":"6"}},[_c('CForm',{on:{"submit":_vm.submitForm}},[_c('div',{staticClass:"form-group vmselect",class:{ valid: _vm.validateInput(_vm.sitesInput.val, 'sites'), invalid: !_vm.validateInput(_vm.sitesInput.val, 'sites') }},[_c('multiselect',{attrs:{"tag-placeholder":"Add this as new tag","placeholder":"Search or add a tag","label":"name","track-by":"code","options":_vm.siteOptions,"multiple":true,"taggable":true,"show-labels":false,"custom-label":_vm.customLabel},model:{value:(_vm.selectedSites),callback:function ($$v) {_vm.selectedSites=$$v},expression:"selectedSites"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(_vm._s(_vm.invalidFeedback('sites')))])],1),_c('CInput',{class:{ valid: _vm.validateInput(_vm.nameInput.val, 'name') },attrs:{"required":"","placeholder":"Game Name","invalidFeedback":_vm.invalidFeedback('name'),"isValid":_vm.validateInput(_vm.nameInput.val, 'name')},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{attrs:{"name":"cil-user"}})]},proxy:true}]),model:{value:(_vm.nameInput.val),callback:function ($$v) {_vm.$set(_vm.nameInput, "val", $$v)},expression:"nameInput.val"}}),_c('CSelect',{class:{ valid: _vm.validateInput(_vm.typeInput.val, 'type') },attrs:{"required":"","placeholder":"Game Type","value":_vm.typeInput.val,"invalidFeedback":_vm.invalidFeedback('type'),"isValid":_vm.validateInput(_vm.typeInput.val, 'type'),"options":[ 
                { value: '', label: 'Unassigned' }, 
                { value: 'SLT', label: 'Slots' }, 
                { value: 'FTB', label: 'Fish Tables' }, 
                { value: 'ARC', label: 'Arcade' }, 
                { value: 'LOT', label: 'Lottery' }, 
                { value: 'ECA', label: 'ECasino' },
                { value: 'OTH', label: 'Others' }  
            ]},on:{"update:value":function($event){return _vm.$set(_vm.typeInput, "val", $event)}},scopedSlots:_vm._u([{key:"prepend-content",fn:function(){return [_c('CIcon',{attrs:{"name":"cil-gamepad"}})]},proxy:true}])}),_c('div',{staticClass:"form-group"},[_c('CInputRadioGroup',{attrs:{"inline":"","options":[{ value: '0', label: 'Newly Synced (Inactive)'}, { value: '1', label: 'Active'}, { value: '2', label: 'Inactive'}],"checked":_vm.statusInput.val},on:{"update:checked":function($event){return _vm.$set(_vm.statusInput, "val", $event)}}})],1),_c('div',{staticClass:"form-group form-actions"},[_c('CButton',{attrs:{"block":"","type":"submit","color":"success","disabled":_vm.formLoading}},[(_vm.formLoading)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e(),_vm._v(" Update ")])],1),_c('div',{staticClass:"form-group form-actions"},[_c('CLink',{attrs:{"disabled":_vm.deleteLoading},on:{"click":function($event){return _vm.deleteItemModalShow()}}},[_c('CIcon',{attrs:{"name":"cil-trash"}}),_vm._v(" Delete this cashtag "),(_vm.deleteLoading)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}):_vm._e()],1)],1)],1)],1)],1)],1)],1),_c('CModal',{attrs:{"title":"Delete Game","color":"danger","centered":true,"show":_vm.deleteModal},on:{"update:show":[function($event){_vm.deleteModal=$event},_vm.deleteItemConfirm]}},[_vm._v(" Are you sure you wish to delete this game '"+_vm._s(_vm.nameInput.val)+"'? ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }