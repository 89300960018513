import request from '@/api/axios-request'

const apiGame = {

  syncPlaystar() {
    return request({
      url: '/ad/games/syncPlaystar',
      method: 'get'
    })
  },

  syncJoker() {
    return request({
      url: '/ad/games/syncJoker',
      method: 'get'
    })
  },

  syncFunky() {
    return request({
      url: '/ad/games/syncFunky',
      method: 'get'
    })
  },

  syncBgaming() {
    return request({
      url: '/ad/games/syncBgaming',
      method: 'get'
    })
  },

  syncOnlyplay() {
    return request({
      url: '/ad/games/syncOnlyplay',
      method: 'get'
    })
  },

  syncKaga() {
    return request({
      url: '/ad/games/syncKaga',
      method: 'get'
    })
  },

  syncVpower() {
    return request({
      url: '/ad/games/syncVpower',
      method: 'get'
    })
  },

  update(id, name, sites, status, type) {
    return request({
      url: '/ad/games/update',
      method: 'post',
      data: {
        id,
        name,
        sites,
        status,
        type
      }
    })
  },

  updateStatus(id, status) {
    return request({
      url: '/ad/games/updateStatus',
      method: 'post',
      data: {
        id,
        status
      }
    })
  },

  updateBulk(ids, operation, sites, tags) {
    return request({
      url: '/ad/games/updateBulk',
      method: 'post',
      data: {
        ids,
        operation,
        sites,
        tags
      }
    })
  },

  delete(id) {
    return request({
      url: '/ad/games/delete',
      method: 'post',
      data: {
        id
      }
    })
  },

  list(page, limit, sortColumn = null, sortOrder = null, filters = {}) {
    return request({
      url: '/ad/games/list',
      method: 'get',
      params: {
        page: page,
        limit: limit,
        sortColumn: sortColumn,
        sortOrder: sortOrder,
        filters: encodeURI(JSON.stringify(filters))
      }
    })
  },

  get(id) {
    return request({
      url: '/ad/games/get',
      method: 'post',
      data: {
        id
      }
    })
  }

}

export default apiGame